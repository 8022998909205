<template>
    <CardTemplate></CardTemplate>
  </template>
  
  <script>
  import CardTemplate from "../../components/payment-link/card.vue";
  export default {
    name:"payment-link",
    components: { CardTemplate }
  };
  </script>
  
  <style>
  </style>